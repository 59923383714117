import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const InlineNotification = makeShortcode("InlineNotification");
const GifPlayer = makeShortcode("GifPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Features</AnchorLink>
  <AnchorLinks small mdxType="AnchorLinks">
    <AnchorLink mdxType="AnchorLink">Stations Deployments</AnchorLink>
    <AnchorLink mdxType="AnchorLink">Admin</AnchorLink>
  </AnchorLinks>
    </AnchorLinks>
    <InlineNotification mdxType="InlineNotification">
  To see images/gifs/vidoes below, please connect to VPN
    </InlineNotification>
    <h2>{`Features`}</h2>
    <h3>{`Stations Deployments`}</h3>
    <h4>{`Wave Planning`}</h4>
    <ul>
      <li parentName="ul">{`Ability to reconnect customer devices to WiFi with enhanced authentication if WiFi credentials are enabled and entered in Customer Settings section prior to the upgrade`}</li>
    </ul>
    <GifPlayer color='dark' mdxType="GifPlayer">
      <img {...{
        "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2022.8.8%252FWifiCredentials.gif&isNativeBrowsing=false",
        "alt": "WiFi credentials"
      }}></img>
      <img {...{
        "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2022.8.8%252FWifiCredentials.png&isNativeBrowsing=false",
        "alt": "WiFi credentials"
      }}></img>
    </GifPlayer>
    <ul>
      <li parentName="ul">{`Simultaneous wave planning by multiple users at the same time`}</li>
    </ul>
    <GifPlayer color='dark' mdxType="GifPlayer">
      <img {...{
        "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2022.8.8%252FSimultaneousWaveEdit.gif&isNativeBrowsing=false",
        "alt": "Muli user wave planning"
      }}></img>
      <img {...{
        "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2022.8.8%252FSimultaneousWaveEdit.png&isNativeBrowsing=false",
        "alt": "Muli user wave planning"
      }}></img>
    </GifPlayer>
    <h3>{`Admin`}</h3>
    <ul>
      <li parentName="ul">{`Admin section is only visible to Admins`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      